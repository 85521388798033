const emailValidation = email => {
  if (!email) return 'メールアドレスを入力してください';

  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!regex.test(email)) return '正しい形式でメールアドレスを入力してください';

  return '';
};

const nameValidation = name => {
  if (!name) return 'お名前を入力してください';
  return '';
};

const textValidation = text => {
  if (!text) return 'お問い合わせ内容を入力してください';
  return '';
};

class Validation {
  // eslint-disable-next-line consistent-return
  static formValidate = (type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'email':
        return emailValidation(value);
      case 'name':
        return nameValidation(value);
      case 'text':
        return textValidation(value);
    }
  };
}

export default Validation;
