import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styled from 'styled-components';
import Triangle from './Triangle';
import Section from './Section';

const DivContainer = styled.div`
  width: 70%;
  margin: 0 15%;
`;

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
    />
  </div>
);

const SocialLink = () => (
  <Section.Container id="twitter" Background={Background}>
    <Section.Header name="Twitter" />
    <DivContainer>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="koyama_line"
        options={{ height: 400 }}
        noHeader="true"
        noFooter="true"
      />
    </DivContainer>
  </Section.Container>
);

export default SocialLink;
