import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Image } from 'rebass';
import Pin from './Logo/Pin.svg';

const key = 'AIzaSyDNa1ugt0CmJ4JIun6m92henOcbWBTuNTM';
const AnyReactComponent = () => <Image src={Pin} />;

// eslint-disable-next-line react/prefer-stateless-function
class Map extends Component {
  static defaultProps = {
    lat: 35.8092947,
    lng: 139.6637793,
    zoom: 16,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key }}
          defaultCenter={{ lat: this.props.lat, lng: this.props.lng }}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={this.props.lat} lng={this.props.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
