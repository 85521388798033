import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Heading, Image } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import Section from '../components/Section';
import MouseIcon from '../components/MouseIcon';

const DivContainer = styled.div`
  background: url(https://images.ctfassets.net/p5xkbp2p2dxr/3pDyLYIbnq564gMkatfH2s/4009a925ae9c4471af96b958b7117e4a/contentload.jpg);
`;
const Video = styled.video`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: auto;
`;
const ProfilePicture = styled(Image)`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: auto;
`;

const url =
  '//videos.ctfassets.net/p5xkbp2p2dxr/4Zw0zX7m8coq159M88pWHt/f87ad3892dae997522cf42524a741ba7/timeLapse.mp4';
const Background = () => (
  <DivContainer>
    <Video className="pc" src={url} autoPlay muted loop playsinline />
    <ProfilePicture
      className="sp"
      src="//images.ctfassets.net/p5xkbp2p2dxr/4n0Zrk0IJlNYcvleHWwFE2/ce5145d6cbb12f4a46e66c8cd40cca13/sp.jpg"
    />
  </DivContainer>
);

const style = { position: 'relative' };

const LandingPage = () => (
  <Section.Container id="home" Background={Background}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          contentfulAbout {
            name
            roles
          }
        }
      `}
      render={data => {
        const { name } = data.contentfulAbout;

        return (
          <Fragment>
            <Heading
              textAlign="center"
              as="h2"
              color="white"
              fontSize={[5, 6, 8]}
              mb={[3, 4, 5]}
              style={style}
            >
              {name}
            </Heading>

            <SectionLink section="about">
              {({ onClick }) => <MouseIcon onClick={onClick} />}
            </SectionLink>
          </Fragment>
        );
      }}
    />
  </Section.Container>
);

export default LandingPage;
