import React, { Component } from 'react';
import AWS from 'aws-sdk';
import Section from './Section';
import Triangle from './Triangle';
import Validation from './Validation';

// Amazon Cognito 認証情報プロバイダーを初期化します
AWS.config.region = 'ap-northeast-1'; // リージョン
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'ap-northeast-1:c2dfcae1-9d96-4d9f-b3c9-a88da54ce5c1',
});
const s3BucketName = 'koyama-contact';

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
    />
  </div>
);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: { name: '', email: '', text: '' },
      message: { name: '', email: '', text: '' },
      loading: false,
    };
  }

  handleChange = event => {
    const key = event.target.name;
    const value = event.target.value;
    const { info, message } = this.state;
    this.setState({
      info: { ...info, [key]: value },
    });
    this.setState({
      message: {
        ...message,
        [key]: Validation.formValidate(key, value),
      },
    });
  };

  canSubmit = () => {
    const { info, message, loading } = this.state;

    const validInfo =
      Object.values(info).filter(value => {
        return value === '';
      }).length === 0;
    const validMessage =
      Object.values(message).filter(value => {
        return value !== '';
      }).length === 0;
    return validInfo && validMessage && !loading;
  };

  submit = () => {
    const { info } = this.state;
    this.setState((prevState, props) => ({
      loading: !prevState.loading,
    }));

    const data = {
      name: info.name,
      email: info.email,
      body: info.text,
    };

    let now = new Date();
    let s3 = new AWS.S3({ params: { Bucket: s3BucketName } });
    let blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'text/plain',
    });
    s3.putObject(
      {
        Key: 'uploads/' + now.getTime() + '.json',
        ContentType: 'text/plain',
        Body: blob,
        ACL: 'public-read',
      },
      function(err, data) {
        if (data !== null) {
          window.alert('お問い合わせの送信が完了しました。');
          setTimeout(location.reload(), 1000);
        } else {
          window.alert('お問い合わせの送信に失敗しました。');
          this.setState((prevState, props) => ({
            loading: !prevState.loading,
          }));
        }
      },
    );
  };

  render() {
    const { info, message } = this.state;
    return (
      <Section.Container id="contact" Background={Background}>
        <Section.Header name="Contact" />
        {/* eslint-disable-next-line no-script-url */}
        <React.Fragment>
          <div className="contact">
            {/* Name */}
            <div className="box">
              <span className="item-name">お名前</span>
              <div className="text-wrap">
                <input
                  type="text"
                  name="name"
                  value={info.name}
                  onChange={this.handleChange.bind(this)}
                />
                {message.name && (
                  <p style={{ color: 'red', fontSize: 8 }}>{message.name}</p>
                )}
              </div>
            </div>
            {/* Email */}
            <div className="box">
              <span className="item-name">メールアドレス</span>
              <div className="text-wrap">
                <input
                  type="email"
                  name="email"
                  value={info.email}
                  onChange={this.handleChange.bind(this)}
                />
                {message.email && (
                  <p style={{ color: 'red', fontSize: 8 }}>{message.email}</p>
                )}
              </div>
            </div>
            {/* お問い合わせ内容 */}
            <div className="box">
              <span className="item-name">お問い合わせ内容</span>
              <div className="textarea-wrap">
                <textarea
                  name="text"
                  rows="8"
                  cols="80"
                  value={info.text}
                  onChange={this.handleChange.bind(this)}
                />
                {message.text && (
                  <p style={{ color: 'red', fontSize: 8 }}>{message.text}</p>
                )}
              </div>
            </div>
            <button
              type="submit"
              disabled={!this.canSubmit()}
              onClick={() => this.submit()}
            >
              送信
            </button>
          </div>
        </React.Fragment>
      </Section.Container>
    );
  }
}

export default Contact;
