import React from 'react';
import { Box, Flex } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import Triangle from '../components/Triangle';
import markdownRenderer from '../components/MarkdownRenderer';
import ConditionalLayout from '../components/ConditionalLayout';

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
    />
  </div>
);

const Recruitment = () => (
  <Section.Container id="recruitment" Background={Background}>
    <Section.Header name="Recruitment" />
    <StaticQuery
      query={graphql`
        query MyQuery {
          contentfulAbout {
            recruitment {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      `}
      render={data => {
        const { recruitment } = data.contentfulAbout;
        return (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
            <Box width={[1, 1, 4 / 6]} px={[1, 2, 4]}>
              <Fade bottom>
                <p>
                  業務拡大のため、一緒に働いてくれるメンバーを募集しております。
                </p>
                <p>
                  経験、未経験問いません。男女、60代以上の人も活躍しています！気になったらまずは、ご応募ください！
                </p>
                <table>
                  <tbody>
                    <tr>
                      <th>募集職種</th>
                      <td>ドライバー</td>
                    </tr>
                    <tr>
                      <th>雇用形態</th>
                      <td>正社員</td>
                    </tr>
                    <tr>
                      <th>給与</th>
                      <td>
                        <pre>2tドライバー</pre>
                        <pre>月給28万円以上</pre>
                        <pre>4tドライバー</pre>
                        <pre>月給32万円以上</pre>
                        <pre> ◆昇給あり</pre>
                        <pre>※試用期間（1ヶ月）※当社規定による</pre>
                        <pre>◆25日締め・翌月5日払い</pre>
                        <pre>◆交通費：支給</pre>
                      </td>
                    </tr>
                    <tr>
                      <th>勤務地</th>
                      <td>
                        <pre>埼玉県戸田市</pre>
                      </td>
                    </tr>
                    <tr>
                      <th>応募資格</th>
                      <td>
                        <pre>未経験者歓迎、経験者優遇</pre>
                        <pre>経験不問、2t・4t要各免許</pre>
                      </td>
                    </tr>
                    <tr>
                      <th>休日</th>
                      <td>
                        <pre>土日祝は基本定休ですが、</pre>
                        <pre>月1～2回程度、出勤の可能性あり。</pre>
                        <pre>その際は休日手当支給。</pre>
                        <pre>夏季・冬期休暇、慶弔休暇あり</pre>
                      </td>
                    </tr>
                    <tr>
                      <th>勤務時間</th>
                      <td>
                        <pre>週5日、1日8時間以上</pre>
                        <pre>※早出・残業あり【5：00～15：00】</pre>
                        <pre>お気軽にご相談ください♪</pre>
                      </td>
                    </tr>
                    <tr>
                      <th>待遇</th>
                      <td>
                        <pre>社会保険完備</pre>
                        <pre>制服貸与</pre>
                        <pre>研修制度あり</pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fade>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

export default Recruitment;
